import React, { useContext, useEffect, useState } from "react";
import MultiStepFormContext from "./MultiStepFormContext";
import { useNavigate } from "react-router-dom";
import { FiRefreshCw } from "react-icons/fi";
import axios from "axios";

const SixthForm = () => {
  const navigate = useNavigate();
  const [checkboxclicked, setCheckBoxClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkboxclickedtwo, setCheckBoxClickedTwo] = useState(false);
  const { formData, currentStep, setCurrentStep } =
    useContext(MultiStepFormContext);
  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const [randomNumbers, setRandomNumbers] = useState({});
  const [userAnswer, setUserAnswer] = useState("");
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  useEffect(() => {
    generateRandomNumbers();
  }, []);

  const generateRandomNumbers = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setRandomNumbers({ num1, num2 });
  };

  const handleRefresh = () => {
    generateRandomNumbers();
  };

  

  const handleSubmit = async () => {
    const expectedAnswer = randomNumbers.num1 + randomNumbers.num2;

    if (parseInt(userAnswer) === expectedAnswer) {
      setIsAnswerCorrect(true);
      setLoading(true); 
      const updatedFormData = { ...formData };
      updatedFormData.masterId = "123";
      updatedFormData.childMasterId = "123";
      updatedFormData.formtype = "qualityconvertform";
      updatedFormData.subject = "QualiConvert Onboarding Form Submission";
      updatedFormData.pdf =
        "https://onboarding.qualiconvert.com/terms-and-conditions-onboarding";
        updatedFormData.domainname= "onboarding.qualiconvert.com";
        updatedFormData.tomail= "qualiconverttech@gmail.com";
    
        updatedFormData.bccmail= "anthony@qualiconvert.com";
        updatedFormData.frommail= "support@qualiconvert.com";
      const formDataObject = new FormData();
      Object.keys(updatedFormData).forEach((key) => {
        formDataObject.append(key, updatedFormData[key]);
      });
     console.log(updatedFormData)
      try {
        const response = await axios.post(
          // "https://onboarding-forms.onrender.com/rmployee/create",
          "https://onboarding-forms.onrender.com/rmployee/create",
         updatedFormData
        );

        if (response.status===201) {
           alert("Form submitted successfully!");

           navigate("/success");
        } else {
          console.error("Failed to submit the form");
        }
      } catch (error) {
        console.error("Error occurred while submitting the form", error);
      }finally {
        setLoading(false); 
      }
    } else {
      setIsAnswerCorrect(false);
      alert("Incorrect captcha");
    }
  };

  return (
    <div>
      <p className="para">
        Thank you for completing your Onboarding Form. Our team will review and
        be in touch on next steps. We are excited to have you join the QualiConvert family.
      </p>
      <div className="checkboxdiv">
        <input
          type="checkbox"
          onClick={() => setCheckBoxClicked(!checkboxclicked)}
        />
        <p>
          By electronically executing this agreement, you agree to all of the
          above
          <span >
            <a
              href="/terms"
              target="_blank"
              className="termslink"
            >
              terms and conditions
            </a>
          </span>
        
        </p>
      </div>
      <div className="checkboxdiv">
        <input
          type="checkbox"
          onClick={() => setCheckBoxClickedTwo(!checkboxclickedtwo)}
        />
        <p>
          By electronically executing this agreement, you agree to all of the
          above
          <span >
          <a
              href="/privacy-policy"
              target="_blank"
              className="termslink"
            >
            Privacy Policy
            </a>
          </span>
        
        </p>
      </div>
      {checkboxclicked && checkboxclickedtwo && (
        <>
          <div className="quizdiv">
            <img
              src="/images/secure.png"
              className="verifydiv"
            />
            <p>
              To verify your identity, please solve the following problem and
              provide your answer in the space provided.
            </p>
          </div>

          <div className="randomdiv">
            <span className="randomnumspan">
              {randomNumbers.num1} + {randomNumbers.num2} = ?
            </span>
            <input
              type="number"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
            />
            <FiRefreshCw onClick={handleRefresh} className="refreshicon" />
          </div>
          <div className="btndiv">
            <button className="Submitbtn" onClick={handleSubmit}>
            {loading ? "Submitting..." : "Submit"}
            </button>
          </div>

          <div className="btns">
            <button className="prev" onClick={handlePrev}>
              Prev
            </button>
            <button className="next disbledbtn" disabled>
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SixthForm;
