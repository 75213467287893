import React, { useState } from "react";
import "./Terms.css";

const Terms = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="termdiv">
      {/* <h1>Terms and Conditions</h1> */}
      <div className="terminnerDiv">
        {isLoading && (
          <div className="loader">
      
          </div>
        )}
        <iframe
          title="Terms PDF"
          src="https://app.termly.io/document/terms-of-service/effb1e46-9396-4886-8732-46dde1f42301"
          width="100%"
          height="1024px"
          style={{ border: "none" }}
          onLoad={handleLoad}
        ></iframe>
      </div>
    </div>
  );
};

export default Terms;
